
:root {
  color-scheme: light;

  --gray: rgb(111, 111, 118);
  --gray1: rgb(255, 255, 255);
  --gray2: rgb(250, 250, 250);
  --gray3: rgb(244, 244, 245);
  --gray4: rgb(239, 239, 240);
  --gray5: rgb(231, 231, 233);
  --gray6: rgb(223, 223, 225);
  --gray7: rgb(218, 218, 220);
  --gray8: rgb(202, 202, 206);
  --gray9: rgb(124, 124, 131);
  --gray10: rgb(111, 111, 118);
  --gray11: rgb(96, 96, 102);
  --gray12: rgb(82, 82, 87);
  --gray13: rgb(67, 67, 71);
  --gray14: rgb(25, 25, 26);
  --green: rgb(41, 122, 113);
  --green1: rgb(247, 252, 252);
  --green2: rgb(240, 250, 249);
  --green3: rgb(232, 247, 246);
  --green4: rgb(224, 245, 242);
  --green5: rgb(209, 240, 236);
  --green6: rgb(198, 236, 231);
  --green7: rgb(186, 232, 227);
  --green8: rgb(156, 222, 214);
  --green9: rgb(55, 164, 152);
  --green10: rgb(41, 122, 113);
  --green11: rgb(36, 107, 99);
  --green12: rgb(29, 88, 81);
  --green13: rgb(24, 73, 67);
  --green14: rgb(8, 23, 21);
  --yellow: rgb(255, 168, 5);
  --yellow1: rgb(255, 253, 250);
  --yellow2: rgb(255, 250, 240);
  --yellow3: rgb(255, 248, 235);
  --yellow4: rgb(255, 246, 229);
  --yellow5: rgb(255, 241, 214);
  --yellow6: rgb(255, 237, 204);
  --yellow7: rgb(255, 234, 194);
  --yellow8: rgb(255, 225, 168);
  --yellow9: rgb(255, 178, 36);
  --yellow10: rgb(255, 168, 5);
  --yellow11: rgb(245, 159, 0);
  --yellow12: rgb(158, 103, 0);
  --yellow13: rgb(92, 60, 0);
  --yellow14: rgb(36, 23, 0);
  --orange: rgb(183, 77, 6);
  --orange1: rgb(255, 252, 250);
  --orange2: rgb(255, 246, 240);
  --orange3: rgb(254, 240, 230);
  --orange4: rgb(254, 237, 225);
  --orange5: rgb(254, 228, 210);
  --orange6: rgb(253, 219, 196);
  --orange7: rgb(253, 213, 186);
  --orange8: rgb(252, 194, 156);
  --orange9: rgb(247, 104, 8);
  --orange10: rgb(183, 77, 6);
  --orange11: rgb(163, 68, 5);
  --orange12: rgb(139, 58, 4);
  --orange13: rgb(114, 48, 4);
  --orange14: rgb(40, 17, 1);
  --red: rgb(214, 31, 37);
  --red1: rgb(254, 251, 251);
  --red2: rgb(253, 242, 242);
  --red3: rgb(252, 237, 238);
  --red4: rgb(252, 233, 233);
  --red5: rgb(251, 224, 225);
  --red6: rgb(249, 215, 216);
  --red7: rgb(248, 206, 207);
  --red8: rgb(245, 184, 186);
  --red9: rgb(229, 72, 77);
  --red10: rgb(214, 31, 37);
  --red11: rgb(187, 27, 32);
  --red12: rgb(161, 23, 28);
  --red13: rgb(134, 19, 23);
  --red14: rgb(49, 7, 8);
  --magenta: rgb(166, 69, 181);
  --magenta1: rgb(253, 251, 254);
  --magenta2: rgb(250, 244, 251);
  --magenta3: rgb(248, 240, 249);
  --magenta4: rgb(245, 233, 247);
  --magenta5: rgb(240, 222, 242);
  --magenta6: rgb(236, 214, 240);
  --magenta7: rgb(231, 203, 235);
  --magenta8: rgb(221, 181, 227);
  --magenta9: rgb(171, 74, 186);
  --magenta10: rgb(166, 69, 181);
  --magenta11: rgb(142, 59, 155);
  --magenta12: rgb(122, 50, 133);
  --magenta13: rgb(98, 41, 107);
  --magenta14: rgb(37, 15, 41);
  --blue: rgb(0, 110, 194);
  --blue1: rgb(245, 251, 255);
  --blue2: rgb(240, 248, 255);
  --blue3: rgb(229, 244, 255);
  --blue4: rgb(224, 242, 255);
  --blue5: rgb(209, 235, 255);
  --blue6: rgb(194, 228, 255);
  --blue7: rgb(184, 224, 255);
  --blue8: rgb(153, 211, 255);
  --blue9: rgb(0, 145, 255);
  --blue10: rgb(0, 110, 194);
  --blue11: rgb(0, 95, 168);
  --blue12: rgb(0, 81, 143);
  --blue13: rgb(0, 66, 117);
  --blue14: rgb(0, 23, 41);

  --blackAlpha1: rgba(0, 0, 0, 0.01);
  --blackAlpha2: rgba(0, 0, 0, 0.02);
  --blackAlpha3: rgba(0, 0, 0, 0.04);
  --blackAlpha4: rgba(0, 0, 0, 0.06);
  --blackAlpha5: rgba(0, 0, 0, 0.09);
  --blackAlpha6: rgba(0, 0, 0, 0.12);
  --blackAlpha7: rgba(0, 0, 0, 0.14);
  --blackAlpha8: rgba(0, 0, 0, 0.2);
  --blackAlpha9: rgba(0, 0, 0, 0.5);
  --blackAlpha10: rgba(0, 0, 0, 0.55);
  --blackAlpha11: rgba(0, 0, 0, 0.61);
  --blackAlpha12: rgba(0, 0, 0, 0.67);
  --blackAlpha13: rgba(0, 0, 0, 0.73);
  --blackAlpha14: rgba(0, 0, 0, 0.9);
}
@media (prefers-color-scheme: dark) {
 :root {
  color-scheme: dark;

    --gray: rgb(132, 132, 139);
    --gray1: rgb(15, 15, 16);
    --gray2: rgb(20, 20, 21);
    --gray3: rgb(27, 27, 29);
    --gray4: rgb(32, 32, 34);
    --gray5: rgb(40, 40, 42);
    --gray6: rgb(49, 49, 53);
    --gray7: rgb(62, 62, 66);
    --gray8: rgb(77, 77, 81);
    --gray9: rgb(124, 124, 131);
    --gray10: rgb(132, 132, 139);
    --gray11: rgb(147, 147, 154);
    --gray12: rgb(166, 166, 171);
    --gray13: rgb(184, 184, 188);
    --gray14: rgb(242, 242, 243);
    --green: rgb(48, 145, 134);
    --green1: rgb(6, 19, 18);
    --green2: rgb(8, 23, 21);
    --green3: rgb(10, 31, 28);
    --green4: rgb(11, 34, 32);
    --green5: rgb(15, 46, 42);
    --green6: rgb(18, 54, 49);
    --green7: rgb(23, 69, 63);
    --green8: rgb(28, 84, 78);
    --green9: rgb(47, 142, 131);
    --green10: rgb(48, 145, 134);
    --green11: rgb(55, 164, 152);
    --green12: rgb(61, 184, 169);
    --green13: rgb(102, 204, 192);
    --green14: rgb(232, 247, 246);
    --yellow: rgb(255, 187, 61);
    --yellow1: rgb(36, 23, 0);
    --yellow2: rgb(51, 33, 0);
    --yellow3: rgb(61, 40, 0);
    --yellow4: rgb(71, 46, 0);
    --yellow5: rgb(97, 63, 0);
    --yellow6: rgb(117, 76, 0);
    --yellow7: rgb(143, 93, 0);
    --yellow8: rgb(179, 116, 0);
    --yellow9: rgb(255, 178, 36);
    --yellow10: rgb(255, 187, 61);
    --yellow11: rgb(255, 196, 87);
    --yellow12: rgb(255, 203, 107);
    --yellow13: rgb(255, 210, 128);
    --yellow14: rgb(255, 253, 250);
    --orange: rgb(228, 95, 7);
    --orange1: rgb(30, 12, 1);
    --orange2: rgb(40, 17, 1);
    --orange3: rgb(54, 23, 2);
    --orange4: rgb(64, 27, 2);
    --orange5: rgb(84, 35, 3);
    --orange6: rgb(99, 41, 3);
    --orange7: rgb(124, 52, 4);
    --orange8: rgb(153, 64, 5);
    --orange9: rgb(218, 91, 7);
    --orange10: rgb(228, 95, 7);
    --orange11: rgb(248, 116, 27);
    --orange12: rgb(249, 140, 67);
    --orange13: rgb(250, 164, 107);
    --orange14: rgb(254, 243, 235);
    --red: rgb(230, 81, 86);
    --red1: rgb(36, 5, 6);
    --red2: rgb(45, 6, 8);
    --red3: rgb(54, 8, 9);
    --red4: rgb(67, 10, 11);
    --red5: rgb(89, 13, 15);
    --red6: rgb(107, 15, 18);
    --red7: rgb(129, 18, 22);
    --red8: rgb(161, 23, 28);
    --red9: rgb(229, 72, 77);
    --red10: rgb(230, 81, 86);
    --red11: rgb(234, 108, 112);
    --red12: rgb(238, 135, 138);
    --red13: rgb(242, 166, 168);
    --red14: rgb(253, 242, 242);
    --magenta: rgb(182, 96, 195);
    --magenta1: rgb(24, 10, 26);
    --magenta2: rgb(31, 13, 33);
    --magenta3: rgb(37, 15, 41);
    --magenta4: rgb(44, 18, 48);
    --magenta5: rgb(58, 24, 63);
    --magenta6: rgb(68, 28, 74);
    --magenta7: rgb(85, 35, 92);
    --magenta8: rgb(105, 43, 115);
    --magenta9: rgb(171, 74, 186);
    --magenta10: rgb(182, 96, 195);
    --magenta11: rgb(193, 122, 205);
    --magenta12: rgb(205, 148, 214);
    --magenta13: rgb(216, 170, 223);
    --magenta14: rgb(248, 240, 249);
    --blue: rgb(0, 136, 240);
    --blue1: rgb(0, 17, 31);
    --blue2: rgb(0, 23, 41);
    --blue3: rgb(0, 32, 56);
    --blue4: rgb(0, 38, 66);
    --blue5: rgb(0, 46, 82);
    --blue6: rgb(0, 58, 102);
    --blue7: rgb(0, 72, 128);
    --blue8: rgb(0, 90, 158);
    --blue9: rgb(0, 130, 230);
    --blue10: rgb(0, 136, 240);
    --blue11: rgb(15, 151, 255);
    --blue12: rgb(66, 173, 255);
    --blue13: rgb(112, 193, 255);
    --blue14: rgb(235, 246, 255);
  }
}
[data-theme="light"] {
 color-scheme: light;

  --gray: rgb(111, 111, 118);
  --gray1: rgb(255, 255, 255);
  --gray2: rgb(250, 250, 250);
  --gray3: rgb(244, 244, 245);
  --gray4: rgb(239, 239, 240);
  --gray5: rgb(231, 231, 233);
  --gray6: rgb(223, 223, 225);
  --gray7: rgb(218, 218, 220);
  --gray8: rgb(202, 202, 206);
  --gray9: rgb(124, 124, 131);
  --gray10: rgb(111, 111, 118);
  --gray11: rgb(96, 96, 102);
  --gray12: rgb(82, 82, 87);
  --gray13: rgb(67, 67, 71);
  --gray14: rgb(25, 25, 26);
  --green: rgb(41, 122, 113);
  --green1: rgb(247, 252, 252);
  --green2: rgb(240, 250, 249);
  --green3: rgb(232, 247, 246);
  --green4: rgb(224, 245, 242);
  --green5: rgb(209, 240, 236);
  --green6: rgb(198, 236, 231);
  --green7: rgb(186, 232, 227);
  --green8: rgb(156, 222, 214);
  --green9: rgb(55, 164, 152);
  --green10: rgb(41, 122, 113);
  --green11: rgb(36, 107, 99);
  --green12: rgb(29, 88, 81);
  --green13: rgb(24, 73, 67);
  --green14: rgb(8, 23, 21);
  --yellow: rgb(255, 168, 5);
  --yellow1: rgb(255, 253, 250);
  --yellow2: rgb(255, 250, 240);
  --yellow3: rgb(255, 248, 235);
  --yellow4: rgb(255, 246, 229);
  --yellow5: rgb(255, 241, 214);
  --yellow6: rgb(255, 237, 204);
  --yellow7: rgb(255, 234, 194);
  --yellow8: rgb(255, 225, 168);
  --yellow9: rgb(255, 178, 36);
  --yellow10: rgb(255, 168, 5);
  --yellow11: rgb(245, 159, 0);
  --yellow12: rgb(158, 103, 0);
  --yellow13: rgb(92, 60, 0);
  --yellow14: rgb(36, 23, 0);
  --orange: rgb(183, 77, 6);
  --orange1: rgb(255, 252, 250);
  --orange2: rgb(255, 246, 240);
  --orange3: rgb(254, 240, 230);
  --orange4: rgb(254, 237, 225);
  --orange5: rgb(254, 228, 210);
  --orange6: rgb(253, 219, 196);
  --orange7: rgb(253, 213, 186);
  --orange8: rgb(252, 194, 156);
  --orange9: rgb(247, 104, 8);
  --orange10: rgb(183, 77, 6);
  --orange11: rgb(163, 68, 5);
  --orange12: rgb(139, 58, 4);
  --orange13: rgb(114, 48, 4);
  --orange14: rgb(40, 17, 1);
  --red: rgb(214, 31, 37);
  --red1: rgb(254, 251, 251);
  --red2: rgb(253, 242, 242);
  --red3: rgb(252, 237, 238);
  --red4: rgb(252, 233, 233);
  --red5: rgb(251, 224, 225);
  --red6: rgb(249, 215, 216);
  --red7: rgb(248, 206, 207);
  --red8: rgb(245, 184, 186);
  --red9: rgb(229, 72, 77);
  --red10: rgb(214, 31, 37);
  --red11: rgb(187, 27, 32);
  --red12: rgb(161, 23, 28);
  --red13: rgb(134, 19, 23);
  --red14: rgb(49, 7, 8);
  --magenta: rgb(166, 69, 181);
  --magenta1: rgb(253, 251, 254);
  --magenta2: rgb(250, 244, 251);
  --magenta3: rgb(248, 240, 249);
  --magenta4: rgb(245, 233, 247);
  --magenta5: rgb(240, 222, 242);
  --magenta6: rgb(236, 214, 240);
  --magenta7: rgb(231, 203, 235);
  --magenta8: rgb(221, 181, 227);
  --magenta9: rgb(171, 74, 186);
  --magenta10: rgb(166, 69, 181);
  --magenta11: rgb(142, 59, 155);
  --magenta12: rgb(122, 50, 133);
  --magenta13: rgb(98, 41, 107);
  --magenta14: rgb(37, 15, 41);
  --blue: rgb(0, 110, 194);
  --blue1: rgb(245, 251, 255);
  --blue2: rgb(240, 248, 255);
  --blue3: rgb(229, 244, 255);
  --blue4: rgb(224, 242, 255);
  --blue5: rgb(209, 235, 255);
  --blue6: rgb(194, 228, 255);
  --blue7: rgb(184, 224, 255);
  --blue8: rgb(153, 211, 255);
  --blue9: rgb(0, 145, 255);
  --blue10: rgb(0, 110, 194);
  --blue11: rgb(0, 95, 168);
  --blue12: rgb(0, 81, 143);
  --blue13: rgb(0, 66, 117);
  --blue14: rgb(0, 23, 41);
}
[data-theme="dark"] {
 color-scheme: dark;

  --gray: rgb(132, 132, 139);
  --gray1: rgb(15, 15, 16);
  --gray2: rgb(20, 20, 21);
  --gray3: rgb(27, 27, 29);
  --gray4: rgb(32, 32, 34);
  --gray5: rgb(40, 40, 42);
  --gray6: rgb(49, 49, 53);
  --gray7: rgb(62, 62, 66);
  --gray8: rgb(77, 77, 81);
  --gray9: rgb(124, 124, 131);
  --gray10: rgb(132, 132, 139);
  --gray11: rgb(147, 147, 154);
  --gray12: rgb(166, 166, 171);
  --gray13: rgb(184, 184, 188);
  --gray14: rgb(242, 242, 243);
  --green: rgb(48, 145, 134);
  --green1: rgb(6, 19, 18);
  --green2: rgb(8, 23, 21);
  --green3: rgb(10, 31, 28);
  --green4: rgb(11, 34, 32);
  --green5: rgb(15, 46, 42);
  --green6: rgb(18, 54, 49);
  --green7: rgb(23, 69, 63);
  --green8: rgb(28, 84, 78);
  --green9: rgb(47, 142, 131);
  --green10: rgb(48, 145, 134);
  --green11: rgb(55, 164, 152);
  --green12: rgb(61, 184, 169);
  --green13: rgb(102, 204, 192);
  --green14: rgb(232, 247, 246);
  --yellow: rgb(255, 187, 61);
  --yellow1: rgb(36, 23, 0);
  --yellow2: rgb(51, 33, 0);
  --yellow3: rgb(61, 40, 0);
  --yellow4: rgb(71, 46, 0);
  --yellow5: rgb(97, 63, 0);
  --yellow6: rgb(117, 76, 0);
  --yellow7: rgb(143, 93, 0);
  --yellow8: rgb(179, 116, 0);
  --yellow9: rgb(255, 178, 36);
  --yellow10: rgb(255, 187, 61);
  --yellow11: rgb(255, 196, 87);
  --yellow12: rgb(255, 203, 107);
  --yellow13: rgb(255, 210, 128);
  --yellow14: rgb(255, 253, 250);
  --orange: rgb(228, 95, 7);
  --orange1: rgb(30, 12, 1);
  --orange2: rgb(40, 17, 1);
  --orange3: rgb(54, 23, 2);
  --orange4: rgb(64, 27, 2);
  --orange5: rgb(84, 35, 3);
  --orange6: rgb(99, 41, 3);
  --orange7: rgb(124, 52, 4);
  --orange8: rgb(153, 64, 5);
  --orange9: rgb(218, 91, 7);
  --orange10: rgb(228, 95, 7);
  --orange11: rgb(248, 116, 27);
  --orange12: rgb(249, 140, 67);
  --orange13: rgb(250, 164, 107);
  --orange14: rgb(254, 243, 235);
  --red: rgb(230, 81, 86);
  --red1: rgb(36, 5, 6);
  --red2: rgb(45, 6, 8);
  --red3: rgb(54, 8, 9);
  --red4: rgb(67, 10, 11);
  --red5: rgb(89, 13, 15);
  --red6: rgb(107, 15, 18);
  --red7: rgb(129, 18, 22);
  --red8: rgb(161, 23, 28);
  --red9: rgb(229, 72, 77);
  --red10: rgb(230, 81, 86);
  --red11: rgb(234, 108, 112);
  --red12: rgb(238, 135, 138);
  --red13: rgb(242, 166, 168);
  --red14: rgb(253, 242, 242);
  --magenta: rgb(182, 96, 195);
  --magenta1: rgb(24, 10, 26);
  --magenta2: rgb(31, 13, 33);
  --magenta3: rgb(37, 15, 41);
  --magenta4: rgb(44, 18, 48);
  --magenta5: rgb(58, 24, 63);
  --magenta6: rgb(68, 28, 74);
  --magenta7: rgb(85, 35, 92);
  --magenta8: rgb(105, 43, 115);
  --magenta9: rgb(171, 74, 186);
  --magenta10: rgb(182, 96, 195);
  --magenta11: rgb(193, 122, 205);
  --magenta12: rgb(205, 148, 214);
  --magenta13: rgb(216, 170, 223);
  --magenta14: rgb(248, 240, 249);
  --blue: rgb(0, 136, 240);
  --blue1: rgb(0, 17, 31);
  --blue2: rgb(0, 23, 41);
  --blue3: rgb(0, 32, 56);
  --blue4: rgb(0, 38, 66);
  --blue5: rgb(0, 46, 82);
  --blue6: rgb(0, 58, 102);
  --blue7: rgb(0, 72, 128);
  --blue8: rgb(0, 90, 158);
  --blue9: rgb(0, 130, 230);
  --blue10: rgb(0, 136, 240);
  --blue11: rgb(15, 151, 255);
  --blue12: rgb(66, 173, 255);
  --blue13: rgb(112, 193, 255);
  --blue14: rgb(235, 246, 255);
}
:root {
  --base-rgb: 244, 244, 245;
  --c0-rgb: 255, 255, 255;
  --c2-rgb: 250, 250, 250;
  --c5-rgb: 223, 223, 225;
  --c10-rgb: 218, 218, 220;
  --c20-rgb: 202, 202, 206;
  --c30-rgb: 111, 111, 118;
  --c40-rgb: 96, 96, 102;
  --c50-rgb: 67, 67, 71;
  --c60-rgb: 25, 25, 26;
  --green-rgb: 41, 122, 113;
  --yellow-rgb: 255, 168, 5;
  --desert-rgb: 183, 77, 6;
  --red-rgb: 214, 31, 37;
  --magenta-rgb: 166, 69, 181;
  --blue-rgb: 0, 110, 194;

  --base: rgb(var(--base-rgb));
  --base-a5: rgba(var(--base-rgb), 0.05);
  --base-a10: rgba(var(--base-rgb), 0.1);
  --base-a20: rgba(var(--base-rgb), 0.2);
  --base-a40: rgba(var(--base-rgb), 0.4);
  --base-a60: rgba(var(--base-rgb), 0.6);
  --base-a80: rgba(var(--base-rgb), 0.8);
  --c0: rgb(var(--c0-rgb));
  --c0-a5: rgba(var(--c0-rgb), 0.05);
  --c0-a10: rgba(var(--c0-rgb), 0.1);
  --c0-a20: rgba(var(--c0-rgb), 0.2);
  --c0-a40: rgba(var(--c0-rgb), 0.4);
  --c0-a60: rgba(var(--c0-rgb), 0.6);
  --c0-a80: rgba(var(--c0-rgb), 0.8);
  --c2: rgb(var(--c2-rgb));
  --c2-a5: rgba(var(--c2-rgb), 0.05);
  --c2-a10: rgba(var(--c2-rgb), 0.1);
  --c2-a20: rgba(var(--c2-rgb), 0.2);
  --c2-a40: rgba(var(--c2-rgb), 0.4);
  --c2-a60: rgba(var(--c2-rgb), 0.6);
  --c2-a80: rgba(var(--c2-rgb), 0.8);
  --c5: rgb(var(--c5-rgb));
  --c5-a5: rgba(var(--c5-rgb), 0.05);
  --c5-a10: rgba(var(--c5-rgb), 0.1);
  --c5-a20: rgba(var(--c5-rgb), 0.2);
  --c5-a40: rgba(var(--c5-rgb), 0.4);
  --c5-a60: rgba(var(--c5-rgb), 0.6);
  --c5-a80: rgba(var(--c5-rgb), 0.8);
  --c10: rgb(var(--c10-rgb));
  --c10-a5: rgba(var(--c10-rgb), 0.05);
  --c10-a10: rgba(var(--c10-rgb), 0.1);
  --c10-a20: rgba(var(--c10-rgb), 0.2);
  --c10-a40: rgba(var(--c10-rgb), 0.4);
  --c10-a60: rgba(var(--c10-rgb), 0.6);
  --c10-a80: rgba(var(--c10-rgb), 0.8);
  --c20: rgb(var(--c20-rgb));
  --c20-a5: rgba(var(--c20-rgb), 0.05);
  --c20-a10: rgba(var(--c20-rgb), 0.1);
  --c20-a20: rgba(var(--c20-rgb), 0.2);
  --c20-a40: rgba(var(--c20-rgb), 0.4);
  --c20-a60: rgba(var(--c20-rgb), 0.6);
  --c20-a80: rgba(var(--c20-rgb), 0.8);
  --c30: rgb(var(--c30-rgb));
  --c30-a5: rgba(var(--c30-rgb), 0.05);
  --c30-a10: rgba(var(--c30-rgb), 0.1);
  --c30-a20: rgba(var(--c30-rgb), 0.2);
  --c30-a40: rgba(var(--c30-rgb), 0.4);
  --c30-a60: rgba(var(--c30-rgb), 0.6);
  --c30-a80: rgba(var(--c30-rgb), 0.8);
  --c40: rgb(var(--c40-rgb));
  --c40-a5: rgba(var(--c40-rgb), 0.05);
  --c40-a10: rgba(var(--c40-rgb), 0.1);
  --c40-a20: rgba(var(--c40-rgb), 0.2);
  --c40-a40: rgba(var(--c40-rgb), 0.4);
  --c40-a60: rgba(var(--c40-rgb), 0.6);
  --c40-a80: rgba(var(--c40-rgb), 0.8);
  --c50: rgb(var(--c50-rgb));
  --c50-a5: rgba(var(--c50-rgb), 0.05);
  --c50-a10: rgba(var(--c50-rgb), 0.1);
  --c50-a20: rgba(var(--c50-rgb), 0.2);
  --c50-a40: rgba(var(--c50-rgb), 0.4);
  --c50-a60: rgba(var(--c50-rgb), 0.6);
  --c50-a80: rgba(var(--c50-rgb), 0.8);
  --c60: rgb(var(--c60-rgb));
  --c60-a5: rgba(var(--c60-rgb), 0.05);
  --c60-a10: rgba(var(--c60-rgb), 0.1);
  --c60-a20: rgba(var(--c60-rgb), 0.2);
  --c60-a40: rgba(var(--c60-rgb), 0.4);
  --c60-a60: rgba(var(--c60-rgb), 0.6);
  --c60-a80: rgba(var(--c60-rgb), 0.8);
  --green: rgb(var(--green-rgb));
  --green-a5: rgba(var(--green-rgb), 0.05);
  --green-a10: rgba(var(--green-rgb), 0.1);
  --green-a20: rgba(var(--green-rgb), 0.2);
  --green-a40: rgba(var(--green-rgb), 0.4);
  --green-a60: rgba(var(--green-rgb), 0.6);
  --green-a80: rgba(var(--green-rgb), 0.8);
  --yellow: rgb(var(--yellow-rgb));
  --yellow-a5: rgba(var(--yellow-rgb), 0.05);
  --yellow-a10: rgba(var(--yellow-rgb), 0.1);
  --yellow-a20: rgba(var(--yellow-rgb), 0.2);
  --yellow-a40: rgba(var(--yellow-rgb), 0.4);
  --yellow-a60: rgba(var(--yellow-rgb), 0.6);
  --yellow-a80: rgba(var(--yellow-rgb), 0.8);
  --desert: rgb(var(--desert-rgb));
  --desert-a5: rgba(var(--desert-rgb), 0.05);
  --desert-a10: rgba(var(--desert-rgb), 0.1);
  --desert-a20: rgba(var(--desert-rgb), 0.2);
  --desert-a40: rgba(var(--desert-rgb), 0.4);
  --desert-a60: rgba(var(--desert-rgb), 0.6);
  --desert-a80: rgba(var(--desert-rgb), 0.8);
  --red: rgb(var(--red-rgb));
  --red-a5: rgba(var(--red-rgb), 0.05);
  --red-a10: rgba(var(--red-rgb), 0.1);
  --red-a20: rgba(var(--red-rgb), 0.2);
  --red-a40: rgba(var(--red-rgb), 0.4);
  --red-a60: rgba(var(--red-rgb), 0.6);
  --red-a80: rgba(var(--red-rgb), 0.8);
  --magenta: rgb(var(--magenta-rgb));
  --magenta-a5: rgba(var(--magenta-rgb), 0.05);
  --magenta-a10: rgba(var(--magenta-rgb), 0.1);
  --magenta-a20: rgba(var(--magenta-rgb), 0.2);
  --magenta-a40: rgba(var(--magenta-rgb), 0.4);
  --magenta-a60: rgba(var(--magenta-rgb), 0.6);
  --magenta-a80: rgba(var(--magenta-rgb), 0.8);
  --blue: rgb(var(--blue-rgb));
  --blue-a5: rgba(var(--blue-rgb), 0.05);
  --blue-a10: rgba(var(--blue-rgb), 0.1);
  --blue-a20: rgba(var(--blue-rgb), 0.2);
  --blue-a40: rgba(var(--blue-rgb), 0.4);
  --blue-a60: rgba(var(--blue-rgb), 0.6);
  --blue-a80: rgba(var(--blue-rgb), 0.8);

}
@media (prefers-color-scheme: dark) {
 :root {
    --base-rgb: 27, 27, 29;
    --c0-rgb: 15, 15, 16;
    --c2-rgb: 20, 20, 21;
    --c5-rgb: 49, 49, 53;
    --c10-rgb: 62, 62, 66;
    --c20-rgb: 77, 77, 81;
    --c30-rgb: 132, 132, 139;
    --c40-rgb: 147, 147, 154;
    --c50-rgb: 184, 184, 188;
    --c60-rgb: 242, 242, 243;
    --green-rgb: 48, 145, 134;
    --yellow-rgb: 255, 187, 61;
    --desert-rgb: 228, 95, 7;
    --red-rgb: 230, 81, 86;
    --magenta-rgb: 182, 96, 195;
    --blue-rgb: 0, 136, 240;

    --base: rgb(var(--base-rgb));
    --base-a5: rgba(var(--base-rgb), 0.05);
    --base-a10: rgba(var(--base-rgb), 0.1);
    --base-a20: rgba(var(--base-rgb), 0.2);
    --base-a40: rgba(var(--base-rgb), 0.4);
    --base-a60: rgba(var(--base-rgb), 0.6);
    --base-a80: rgba(var(--base-rgb), 0.8);
    --c0: rgb(var(--c0-rgb));
    --c0-a5: rgba(var(--c0-rgb), 0.05);
    --c0-a10: rgba(var(--c0-rgb), 0.1);
    --c0-a20: rgba(var(--c0-rgb), 0.2);
    --c0-a40: rgba(var(--c0-rgb), 0.4);
    --c0-a60: rgba(var(--c0-rgb), 0.6);
    --c0-a80: rgba(var(--c0-rgb), 0.8);
    --c2: rgb(var(--c2-rgb));
    --c2-a5: rgba(var(--c2-rgb), 0.05);
    --c2-a10: rgba(var(--c2-rgb), 0.1);
    --c2-a20: rgba(var(--c2-rgb), 0.2);
    --c2-a40: rgba(var(--c2-rgb), 0.4);
    --c2-a60: rgba(var(--c2-rgb), 0.6);
    --c2-a80: rgba(var(--c2-rgb), 0.8);
    --c5: rgb(var(--c5-rgb));
    --c5-a5: rgba(var(--c5-rgb), 0.05);
    --c5-a10: rgba(var(--c5-rgb), 0.1);
    --c5-a20: rgba(var(--c5-rgb), 0.2);
    --c5-a40: rgba(var(--c5-rgb), 0.4);
    --c5-a60: rgba(var(--c5-rgb), 0.6);
    --c5-a80: rgba(var(--c5-rgb), 0.8);
    --c10: rgb(var(--c10-rgb));
    --c10-a5: rgba(var(--c10-rgb), 0.05);
    --c10-a10: rgba(var(--c10-rgb), 0.1);
    --c10-a20: rgba(var(--c10-rgb), 0.2);
    --c10-a40: rgba(var(--c10-rgb), 0.4);
    --c10-a60: rgba(var(--c10-rgb), 0.6);
    --c10-a80: rgba(var(--c10-rgb), 0.8);
    --c20: rgb(var(--c20-rgb));
    --c20-a5: rgba(var(--c20-rgb), 0.05);
    --c20-a10: rgba(var(--c20-rgb), 0.1);
    --c20-a20: rgba(var(--c20-rgb), 0.2);
    --c20-a40: rgba(var(--c20-rgb), 0.4);
    --c20-a60: rgba(var(--c20-rgb), 0.6);
    --c20-a80: rgba(var(--c20-rgb), 0.8);
    --c30: rgb(var(--c30-rgb));
    --c30-a5: rgba(var(--c30-rgb), 0.05);
    --c30-a10: rgba(var(--c30-rgb), 0.1);
    --c30-a20: rgba(var(--c30-rgb), 0.2);
    --c30-a40: rgba(var(--c30-rgb), 0.4);
    --c30-a60: rgba(var(--c30-rgb), 0.6);
    --c30-a80: rgba(var(--c30-rgb), 0.8);
    --c40: rgb(var(--c40-rgb));
    --c40-a5: rgba(var(--c40-rgb), 0.05);
    --c40-a10: rgba(var(--c40-rgb), 0.1);
    --c40-a20: rgba(var(--c40-rgb), 0.2);
    --c40-a40: rgba(var(--c40-rgb), 0.4);
    --c40-a60: rgba(var(--c40-rgb), 0.6);
    --c40-a80: rgba(var(--c40-rgb), 0.8);
    --c50: rgb(var(--c50-rgb));
    --c50-a5: rgba(var(--c50-rgb), 0.05);
    --c50-a10: rgba(var(--c50-rgb), 0.1);
    --c50-a20: rgba(var(--c50-rgb), 0.2);
    --c50-a40: rgba(var(--c50-rgb), 0.4);
    --c50-a60: rgba(var(--c50-rgb), 0.6);
    --c50-a80: rgba(var(--c50-rgb), 0.8);
    --c60: rgb(var(--c60-rgb));
    --c60-a5: rgba(var(--c60-rgb), 0.05);
    --c60-a10: rgba(var(--c60-rgb), 0.1);
    --c60-a20: rgba(var(--c60-rgb), 0.2);
    --c60-a40: rgba(var(--c60-rgb), 0.4);
    --c60-a60: rgba(var(--c60-rgb), 0.6);
    --c60-a80: rgba(var(--c60-rgb), 0.8);
    --green: rgb(var(--green-rgb));
    --green-a5: rgba(var(--green-rgb), 0.05);
    --green-a10: rgba(var(--green-rgb), 0.1);
    --green-a20: rgba(var(--green-rgb), 0.2);
    --green-a40: rgba(var(--green-rgb), 0.4);
    --green-a60: rgba(var(--green-rgb), 0.6);
    --green-a80: rgba(var(--green-rgb), 0.8);
    --yellow: rgb(var(--yellow-rgb));
    --yellow-a5: rgba(var(--yellow-rgb), 0.05);
    --yellow-a10: rgba(var(--yellow-rgb), 0.1);
    --yellow-a20: rgba(var(--yellow-rgb), 0.2);
    --yellow-a40: rgba(var(--yellow-rgb), 0.4);
    --yellow-a60: rgba(var(--yellow-rgb), 0.6);
    --yellow-a80: rgba(var(--yellow-rgb), 0.8);
    --desert: rgb(var(--desert-rgb));
    --desert-a5: rgba(var(--desert-rgb), 0.05);
    --desert-a10: rgba(var(--desert-rgb), 0.1);
    --desert-a20: rgba(var(--desert-rgb), 0.2);
    --desert-a40: rgba(var(--desert-rgb), 0.4);
    --desert-a60: rgba(var(--desert-rgb), 0.6);
    --desert-a80: rgba(var(--desert-rgb), 0.8);
    --red: rgb(var(--red-rgb));
    --red-a5: rgba(var(--red-rgb), 0.05);
    --red-a10: rgba(var(--red-rgb), 0.1);
    --red-a20: rgba(var(--red-rgb), 0.2);
    --red-a40: rgba(var(--red-rgb), 0.4);
    --red-a60: rgba(var(--red-rgb), 0.6);
    --red-a80: rgba(var(--red-rgb), 0.8);
    --magenta: rgb(var(--magenta-rgb));
    --magenta-a5: rgba(var(--magenta-rgb), 0.05);
    --magenta-a10: rgba(var(--magenta-rgb), 0.1);
    --magenta-a20: rgba(var(--magenta-rgb), 0.2);
    --magenta-a40: rgba(var(--magenta-rgb), 0.4);
    --magenta-a60: rgba(var(--magenta-rgb), 0.6);
    --magenta-a80: rgba(var(--magenta-rgb), 0.8);
    --blue: rgb(var(--blue-rgb));
    --blue-a5: rgba(var(--blue-rgb), 0.05);
    --blue-a10: rgba(var(--blue-rgb), 0.1);
    --blue-a20: rgba(var(--blue-rgb), 0.2);
    --blue-a40: rgba(var(--blue-rgb), 0.4);
    --blue-a60: rgba(var(--blue-rgb), 0.6);
    --blue-a80: rgba(var(--blue-rgb), 0.8);

  }
}
[data-theme="dark"] {
  --base-rgb: 27, 27, 29;
  --c0-rgb: 15, 15, 16;
  --c2-rgb: 20, 20, 21;
  --c5-rgb: 49, 49, 53;
  --c10-rgb: 62, 62, 66;
  --c20-rgb: 77, 77, 81;
  --c30-rgb: 132, 132, 139;
  --c40-rgb: 147, 147, 154;
  --c50-rgb: 184, 184, 188;
  --c60-rgb: 242, 242, 243;
  --green-rgb: 48, 145, 134;
  --yellow-rgb: 255, 187, 61;
  --desert-rgb: 228, 95, 7;
  --red-rgb: 230, 81, 86;
  --magenta-rgb: 182, 96, 195;
  --blue-rgb: 0, 136, 240;

  --base: rgb(var(--base-rgb));
  --base-a5: rgba(var(--base-rgb), 0.05);
  --base-a10: rgba(var(--base-rgb), 0.1);
  --base-a20: rgba(var(--base-rgb), 0.2);
  --base-a40: rgba(var(--base-rgb), 0.4);
  --base-a60: rgba(var(--base-rgb), 0.6);
  --base-a80: rgba(var(--base-rgb), 0.8);
  --c0: rgb(var(--c0-rgb));
  --c0-a5: rgba(var(--c0-rgb), 0.05);
  --c0-a10: rgba(var(--c0-rgb), 0.1);
  --c0-a20: rgba(var(--c0-rgb), 0.2);
  --c0-a40: rgba(var(--c0-rgb), 0.4);
  --c0-a60: rgba(var(--c0-rgb), 0.6);
  --c0-a80: rgba(var(--c0-rgb), 0.8);
  --c2: rgb(var(--c2-rgb));
  --c2-a5: rgba(var(--c2-rgb), 0.05);
  --c2-a10: rgba(var(--c2-rgb), 0.1);
  --c2-a20: rgba(var(--c2-rgb), 0.2);
  --c2-a40: rgba(var(--c2-rgb), 0.4);
  --c2-a60: rgba(var(--c2-rgb), 0.6);
  --c2-a80: rgba(var(--c2-rgb), 0.8);
  --c5: rgb(var(--c5-rgb));
  --c5-a5: rgba(var(--c5-rgb), 0.05);
  --c5-a10: rgba(var(--c5-rgb), 0.1);
  --c5-a20: rgba(var(--c5-rgb), 0.2);
  --c5-a40: rgba(var(--c5-rgb), 0.4);
  --c5-a60: rgba(var(--c5-rgb), 0.6);
  --c5-a80: rgba(var(--c5-rgb), 0.8);
  --c10: rgb(var(--c10-rgb));
  --c10-a5: rgba(var(--c10-rgb), 0.05);
  --c10-a10: rgba(var(--c10-rgb), 0.1);
  --c10-a20: rgba(var(--c10-rgb), 0.2);
  --c10-a40: rgba(var(--c10-rgb), 0.4);
  --c10-a60: rgba(var(--c10-rgb), 0.6);
  --c10-a80: rgba(var(--c10-rgb), 0.8);
  --c20: rgb(var(--c20-rgb));
  --c20-a5: rgba(var(--c20-rgb), 0.05);
  --c20-a10: rgba(var(--c20-rgb), 0.1);
  --c20-a20: rgba(var(--c20-rgb), 0.2);
  --c20-a40: rgba(var(--c20-rgb), 0.4);
  --c20-a60: rgba(var(--c20-rgb), 0.6);
  --c20-a80: rgba(var(--c20-rgb), 0.8);
  --c30: rgb(var(--c30-rgb));
  --c30-a5: rgba(var(--c30-rgb), 0.05);
  --c30-a10: rgba(var(--c30-rgb), 0.1);
  --c30-a20: rgba(var(--c30-rgb), 0.2);
  --c30-a40: rgba(var(--c30-rgb), 0.4);
  --c30-a60: rgba(var(--c30-rgb), 0.6);
  --c30-a80: rgba(var(--c30-rgb), 0.8);
  --c40: rgb(var(--c40-rgb));
  --c40-a5: rgba(var(--c40-rgb), 0.05);
  --c40-a10: rgba(var(--c40-rgb), 0.1);
  --c40-a20: rgba(var(--c40-rgb), 0.2);
  --c40-a40: rgba(var(--c40-rgb), 0.4);
  --c40-a60: rgba(var(--c40-rgb), 0.6);
  --c40-a80: rgba(var(--c40-rgb), 0.8);
  --c50: rgb(var(--c50-rgb));
  --c50-a5: rgba(var(--c50-rgb), 0.05);
  --c50-a10: rgba(var(--c50-rgb), 0.1);
  --c50-a20: rgba(var(--c50-rgb), 0.2);
  --c50-a40: rgba(var(--c50-rgb), 0.4);
  --c50-a60: rgba(var(--c50-rgb), 0.6);
  --c50-a80: rgba(var(--c50-rgb), 0.8);
  --c60: rgb(var(--c60-rgb));
  --c60-a5: rgba(var(--c60-rgb), 0.05);
  --c60-a10: rgba(var(--c60-rgb), 0.1);
  --c60-a20: rgba(var(--c60-rgb), 0.2);
  --c60-a40: rgba(var(--c60-rgb), 0.4);
  --c60-a60: rgba(var(--c60-rgb), 0.6);
  --c60-a80: rgba(var(--c60-rgb), 0.8);
  --green: rgb(var(--green-rgb));
  --green-a5: rgba(var(--green-rgb), 0.05);
  --green-a10: rgba(var(--green-rgb), 0.1);
  --green-a20: rgba(var(--green-rgb), 0.2);
  --green-a40: rgba(var(--green-rgb), 0.4);
  --green-a60: rgba(var(--green-rgb), 0.6);
  --green-a80: rgba(var(--green-rgb), 0.8);
  --yellow: rgb(var(--yellow-rgb));
  --yellow-a5: rgba(var(--yellow-rgb), 0.05);
  --yellow-a10: rgba(var(--yellow-rgb), 0.1);
  --yellow-a20: rgba(var(--yellow-rgb), 0.2);
  --yellow-a40: rgba(var(--yellow-rgb), 0.4);
  --yellow-a60: rgba(var(--yellow-rgb), 0.6);
  --yellow-a80: rgba(var(--yellow-rgb), 0.8);
  --desert: rgb(var(--desert-rgb));
  --desert-a5: rgba(var(--desert-rgb), 0.05);
  --desert-a10: rgba(var(--desert-rgb), 0.1);
  --desert-a20: rgba(var(--desert-rgb), 0.2);
  --desert-a40: rgba(var(--desert-rgb), 0.4);
  --desert-a60: rgba(var(--desert-rgb), 0.6);
  --desert-a80: rgba(var(--desert-rgb), 0.8);
  --red: rgb(var(--red-rgb));
  --red-a5: rgba(var(--red-rgb), 0.05);
  --red-a10: rgba(var(--red-rgb), 0.1);
  --red-a20: rgba(var(--red-rgb), 0.2);
  --red-a40: rgba(var(--red-rgb), 0.4);
  --red-a60: rgba(var(--red-rgb), 0.6);
  --red-a80: rgba(var(--red-rgb), 0.8);
  --magenta: rgb(var(--magenta-rgb));
  --magenta-a5: rgba(var(--magenta-rgb), 0.05);
  --magenta-a10: rgba(var(--magenta-rgb), 0.1);
  --magenta-a20: rgba(var(--magenta-rgb), 0.2);
  --magenta-a40: rgba(var(--magenta-rgb), 0.4);
  --magenta-a60: rgba(var(--magenta-rgb), 0.6);
  --magenta-a80: rgba(var(--magenta-rgb), 0.8);
  --blue: rgb(var(--blue-rgb));
  --blue-a5: rgba(var(--blue-rgb), 0.05);
  --blue-a10: rgba(var(--blue-rgb), 0.1);
  --blue-a20: rgba(var(--blue-rgb), 0.2);
  --blue-a40: rgba(var(--blue-rgb), 0.4);
  --blue-a60: rgba(var(--blue-rgb), 0.6);
  --blue-a80: rgba(var(--blue-rgb), 0.8);

}
[data-theme="light"] {
  --base-rgb: 244, 244, 245;
  --c0-rgb: 255, 255, 255;
  --c2-rgb: 250, 250, 250;
  --c5-rgb: 223, 223, 225;
  --c10-rgb: 218, 218, 220;
  --c20-rgb: 202, 202, 206;
  --c30-rgb: 111, 111, 118;
  --c40-rgb: 96, 96, 102;
  --c50-rgb: 67, 67, 71;
  --c60-rgb: 25, 25, 26;
  --green-rgb: 41, 122, 113;
  --yellow-rgb: 255, 168, 5;
  --desert-rgb: 183, 77, 6;
  --red-rgb: 214, 31, 37;
  --magenta-rgb: 166, 69, 181;
  --blue-rgb: 0, 110, 194;

  --base: rgb(var(--base-rgb));
  --base-a5: rgba(var(--base-rgb), 0.05);
  --base-a10: rgba(var(--base-rgb), 0.1);
  --base-a20: rgba(var(--base-rgb), 0.2);
  --base-a40: rgba(var(--base-rgb), 0.4);
  --base-a60: rgba(var(--base-rgb), 0.6);
  --base-a80: rgba(var(--base-rgb), 0.8);
  --c0: rgb(var(--c0-rgb));
  --c0-a5: rgba(var(--c0-rgb), 0.05);
  --c0-a10: rgba(var(--c0-rgb), 0.1);
  --c0-a20: rgba(var(--c0-rgb), 0.2);
  --c0-a40: rgba(var(--c0-rgb), 0.4);
  --c0-a60: rgba(var(--c0-rgb), 0.6);
  --c0-a80: rgba(var(--c0-rgb), 0.8);
  --c2: rgb(var(--c2-rgb));
  --c2-a5: rgba(var(--c2-rgb), 0.05);
  --c2-a10: rgba(var(--c2-rgb), 0.1);
  --c2-a20: rgba(var(--c2-rgb), 0.2);
  --c2-a40: rgba(var(--c2-rgb), 0.4);
  --c2-a60: rgba(var(--c2-rgb), 0.6);
  --c2-a80: rgba(var(--c2-rgb), 0.8);
  --c5: rgb(var(--c5-rgb));
  --c5-a5: rgba(var(--c5-rgb), 0.05);
  --c5-a10: rgba(var(--c5-rgb), 0.1);
  --c5-a20: rgba(var(--c5-rgb), 0.2);
  --c5-a40: rgba(var(--c5-rgb), 0.4);
  --c5-a60: rgba(var(--c5-rgb), 0.6);
  --c5-a80: rgba(var(--c5-rgb), 0.8);
  --c10: rgb(var(--c10-rgb));
  --c10-a5: rgba(var(--c10-rgb), 0.05);
  --c10-a10: rgba(var(--c10-rgb), 0.1);
  --c10-a20: rgba(var(--c10-rgb), 0.2);
  --c10-a40: rgba(var(--c10-rgb), 0.4);
  --c10-a60: rgba(var(--c10-rgb), 0.6);
  --c10-a80: rgba(var(--c10-rgb), 0.8);
  --c20: rgb(var(--c20-rgb));
  --c20-a5: rgba(var(--c20-rgb), 0.05);
  --c20-a10: rgba(var(--c20-rgb), 0.1);
  --c20-a20: rgba(var(--c20-rgb), 0.2);
  --c20-a40: rgba(var(--c20-rgb), 0.4);
  --c20-a60: rgba(var(--c20-rgb), 0.6);
  --c20-a80: rgba(var(--c20-rgb), 0.8);
  --c30: rgb(var(--c30-rgb));
  --c30-a5: rgba(var(--c30-rgb), 0.05);
  --c30-a10: rgba(var(--c30-rgb), 0.1);
  --c30-a20: rgba(var(--c30-rgb), 0.2);
  --c30-a40: rgba(var(--c30-rgb), 0.4);
  --c30-a60: rgba(var(--c30-rgb), 0.6);
  --c30-a80: rgba(var(--c30-rgb), 0.8);
  --c40: rgb(var(--c40-rgb));
  --c40-a5: rgba(var(--c40-rgb), 0.05);
  --c40-a10: rgba(var(--c40-rgb), 0.1);
  --c40-a20: rgba(var(--c40-rgb), 0.2);
  --c40-a40: rgba(var(--c40-rgb), 0.4);
  --c40-a60: rgba(var(--c40-rgb), 0.6);
  --c40-a80: rgba(var(--c40-rgb), 0.8);
  --c50: rgb(var(--c50-rgb));
  --c50-a5: rgba(var(--c50-rgb), 0.05);
  --c50-a10: rgba(var(--c50-rgb), 0.1);
  --c50-a20: rgba(var(--c50-rgb), 0.2);
  --c50-a40: rgba(var(--c50-rgb), 0.4);
  --c50-a60: rgba(var(--c50-rgb), 0.6);
  --c50-a80: rgba(var(--c50-rgb), 0.8);
  --c60: rgb(var(--c60-rgb));
  --c60-a5: rgba(var(--c60-rgb), 0.05);
  --c60-a10: rgba(var(--c60-rgb), 0.1);
  --c60-a20: rgba(var(--c60-rgb), 0.2);
  --c60-a40: rgba(var(--c60-rgb), 0.4);
  --c60-a60: rgba(var(--c60-rgb), 0.6);
  --c60-a80: rgba(var(--c60-rgb), 0.8);
  --green: rgb(var(--green-rgb));
  --green-a5: rgba(var(--green-rgb), 0.05);
  --green-a10: rgba(var(--green-rgb), 0.1);
  --green-a20: rgba(var(--green-rgb), 0.2);
  --green-a40: rgba(var(--green-rgb), 0.4);
  --green-a60: rgba(var(--green-rgb), 0.6);
  --green-a80: rgba(var(--green-rgb), 0.8);
  --yellow: rgb(var(--yellow-rgb));
  --yellow-a5: rgba(var(--yellow-rgb), 0.05);
  --yellow-a10: rgba(var(--yellow-rgb), 0.1);
  --yellow-a20: rgba(var(--yellow-rgb), 0.2);
  --yellow-a40: rgba(var(--yellow-rgb), 0.4);
  --yellow-a60: rgba(var(--yellow-rgb), 0.6);
  --yellow-a80: rgba(var(--yellow-rgb), 0.8);
  --desert: rgb(var(--desert-rgb));
  --desert-a5: rgba(var(--desert-rgb), 0.05);
  --desert-a10: rgba(var(--desert-rgb), 0.1);
  --desert-a20: rgba(var(--desert-rgb), 0.2);
  --desert-a40: rgba(var(--desert-rgb), 0.4);
  --desert-a60: rgba(var(--desert-rgb), 0.6);
  --desert-a80: rgba(var(--desert-rgb), 0.8);
  --red: rgb(var(--red-rgb));
  --red-a5: rgba(var(--red-rgb), 0.05);
  --red-a10: rgba(var(--red-rgb), 0.1);
  --red-a20: rgba(var(--red-rgb), 0.2);
  --red-a40: rgba(var(--red-rgb), 0.4);
  --red-a60: rgba(var(--red-rgb), 0.6);
  --red-a80: rgba(var(--red-rgb), 0.8);
  --magenta: rgb(var(--magenta-rgb));
  --magenta-a5: rgba(var(--magenta-rgb), 0.05);
  --magenta-a10: rgba(var(--magenta-rgb), 0.1);
  --magenta-a20: rgba(var(--magenta-rgb), 0.2);
  --magenta-a40: rgba(var(--magenta-rgb), 0.4);
  --magenta-a60: rgba(var(--magenta-rgb), 0.6);
  --magenta-a80: rgba(var(--magenta-rgb), 0.8);
  --blue: rgb(var(--blue-rgb));
  --blue-a5: rgba(var(--blue-rgb), 0.05);
  --blue-a10: rgba(var(--blue-rgb), 0.1);
  --blue-a20: rgba(var(--blue-rgb), 0.2);
  --blue-a40: rgba(var(--blue-rgb), 0.4);
  --blue-a60: rgba(var(--blue-rgb), 0.6);
  --blue-a80: rgba(var(--blue-rgb), 0.8);

}
@font-face {
  font-family: "Inter";
  font-weight: 300 800;
  font-display: swap;
  font-style: normal;
  font-named-instance: "Regular";
  src: url("https://www.stedi.com/fonts/Inter-roman.var.woff2");
}
@font-face {
  font-family: "Recursive";
  font-weight: 300 800;
  font-display: swap;
  font-style: normal;
  font-named-instance: "Regular";
  src: url("https://www.stedi.com/fonts/Recursive.woff2");
}
/* Color variables */
:root {
  --fontweight: 400;
  --tabularfigures: off;
  --slashedzero: off;
  --mono: 0;
}
/* Reset */
*,
*::before,
*::after {
  box-sizing: border-box;
  font-variation-settings: "wght" var(--fontweight);
  font-feature-settings: "tnum" var(--tabularfigures), "zero" var(--slashedzero);
}
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd,
pre,
address {
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: inherit;
  font-weight: normal;
}
ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}
a:not([class]) {
  text-decoration-skip-ink: auto;
}
img {
  display: block;
  max-width: 100%;
}
input,
button,
textarea,
select {
  font: inherit;
}
fieldset {
  padding: 0;
  border: 0;
  margin: 0;
}
button {
  border: 0;
  padding: 0;
  appearance: none;
  background-color: transparent;
  border-radius: 0;
  outline: none;
}
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
    transition-duration: 0.01ms !important;
  }
}
/* Global styles */
html {
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  line-height: 1.3;
}
body {
  background-color: var(--gray1);
  color: var(--gray13);
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
}
body,
main {
  min-height: 100vh;
}
svg {
  flex: none;
}
*::selection {
  color: var(--c60);
  background-color: var(--blue-a60);
}
@media print {
  @page {
    margin: 0;
    size: auto;
  }

  body {
    background-color: white;
    margin: 10mm 15mm;
  }
}
